<template>
  <div class="home">
    <div class="bannerBg">
      <div class="bg"></div>
      <video :src="systemSettins.indexBannerVideo" autoplay loop style="width:100%;"></video>
      <div class="banerTxt">
        <p>{{ systemSettins.indexBannerTitle2 }}</p>
        <h3>{{ systemSettins.indexBannerTitle1 }}</h3>
        <a class="btn" @click="jump('/Navigation/index')">进入{{ systemSettins.indexBannerTitle1 }}</a>
      </div>
    </div>
    <div class="main">
      <div class="inner">
        <div class="left">
          <img :src="systemSettins.projectImage" />
        </div>
        <div class="right">
          <h3>{{ systemSettins.projectTitle }}</h3>
          <p>
            {{ systemSettins.projectContent }}
          </p>
          <a class="btn" @click="jump('/Team/index')">了解更多</a>
          <!-- <div class="hot">
            <h3>校园浏览量排行</h3>
            <ul class="schoolsTop">

              <li v-for="(item,index) in schoolsTop" :key="index" v-show="index<10">{{index+1}}{{
                item.label
              }}<span style="color: #f00;">Hot</span></li>

            </ul>
          </div> -->
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="top">
        <h3>联系我们</h3>
        <p>Email: {{ systemSettins.email }}</p>
      </div>
      <div class="bottom">
        <span>{{ systemSettins.copyright }}</span> &nbsp;
        <a href="https://beian.miit.gov.cn" target="_blank">备案号：{{ systemSettins.filings }}</a>
        <span>联系方式：{{ systemSettins.email }}</span>
        <span style="text-decoration: underline; cursor: pointer;" @click="letterFn">致谢与致歉</span>
      </div>
    </div>
    <letter ref="letter" :systemSettins="systemSettins"></letter>
  </div>
</template>

<script>
import { schoolsTop, settingsDetail } from "@/api/Mange/Mange";
import letter from "@/components/letter.vue";
export default {
  components: {
    letter,
  },
  data() {
    return {
      schoolsTop: [],
      systemSettins: {}
    };
  },
  created() {
    // this.init();
    settingsDetail().then(res => {
      this.systemSettins = res.data;
    })
  },
  methods: {
    letterFn(){
      this.$refs.letter.showDialog();
    },
    init() {
      schoolsTop().then((res) => {
        if (res.status) {
          this.schoolsTop = res.data;
        } else {
          this.$message("获取校园浏览量排行错误！");
        }
      });
    },
    jump(path) {
      this.$router.push({ path: path });
    },
  },
};
</script>
<style lang="less">
.home {
  .bannerBg {
    position: relative;
    height: 975px;
    overflow: hidden;
    .bg {
      background: #0e092b;
      opacity: 0.5;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
    }
    .banerTxt {
      text-align: center;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 3;
      p {
        color: #fff;
        padding-top: 250px;
        font-size: 24px;
        margin: 0;
        letter-spacing: 0.7em;
      }
      h3 {
        color: rgb(230, 242, 238);
        font-size: 135px;
        font-family: "schoolh3";
        font-weight: normal;
        line-height: 190px;
        margin-bottom: 30px;
      }
      .btn {
        width: 160px;
        height: 40px;
        background-color: #d14649;
        color: #3e3131;
        text-align: center;
        line-height: 40px;
        display: block;
        margin: 0 auto;
        cursor: pointer;
        transition: 0.5s;
      }
      .btn:hover {
        background-color: #0e092b;
        color: #d14649;
      }
    }
  }
  .main {
    height: 496px;
    background-color: #e6f2ee;
    .inner {
      width: 980px;
      height: 490px;
      background-color: #fff;
      margin: 0 auto;

      .left {
        float: left;
        width: 430px;
        img {
          width: 430px;
          height: 430px;
        }
      }
      .right {
        float: right;
        width: 420px;
        margin-right: 60px;
        position: relative;
        .hot {
          position: absolute;
          right: -270px;
          width: 200px;
          top: 36px;
          line-height: 32px;
          h3 {
            font-size: 24px;
          }
        }
        h3 {
          font-size: 36px;
          margin: 40px 0 8px 0;
          font-family: "schoolh3";
          font-weight: normal;
        }
        p {
          text-align: left;
          margin: 0;
          line-height: 32px;
        }
        .btn {
          width: 160px;
          height: 40px;
          background-color: #d14649;
          color: #3e3131;
          text-align: center;
          line-height: 40px;
          display: block;
          cursor: pointer;
          transition: 0.5s;
        }
        .btn:hover {
          background-color: #0e092b;
          color: #d14649;
        }
      }
    }
  }
  .footer {
    text-align: center;
    .top {
      background-color: #0e092b;
      color: #fff;
      height: 183px;
      h3 {
        color: #fff;
        font-size: 30px;
        line-height: 120px;
        margin: 0;
        font-family: "schoolh3";
        font-weight: normal;
      }
      p {
        font-size: 14px;
      }
    }
    .bottom {
      height: 40px;
      line-height: 40px;
      text-align: center;
    }
  }
}
</style>
