<template>
  <div class="letter">
    <el-dialog
      :title="systemSettins.thankTitle"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div style="font-size: 16px; color: #fff;">
        {{ systemSettins.thankContent }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: [ 'systemSettins' ],
  data() {
    return {
      dialogVisible: false,
    };
  },
  created() {},
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    showDialog() {
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less">
.letter {
  p {
    color: #fff;
    line-height: 30px;
  }
  .el-select {
    width: 100%;
  }
  .el-input__inner {
    border: 1px solid #333;
    background-color: #0e092b;
  }

  .el-button {
    background-color: #d14649;
    color: #3e3131;
    border: none;
  }
  .el-textarea__inner {
    padding: 5px 15px;
    font-size: 14px;
    border: 1px solid #333;
    background-color: #0e092b;
    color: #fff;
    width: 100%;
    height: 80px;
  }
}
</style>
